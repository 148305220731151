import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Button, Grid, MenuItem } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { FuseAnimate } from '@fuse';
import clsx from 'clsx';
import JWTLoginTab from './tabs/JWTLoginTab';
import Formsy from "formsy-react";
import { SelectFormsy, TextFieldFormsy } from "@fuse";
import * as authActions from 'app/auth/store/actions';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmployeeService from 'app/services/EmployeeService';
import { useDispatch } from "react-redux";
import * as Actions from "app/store/actions";
import ControlService from 'app/services/ControlService';
import { useMsal } from "@azure/msal-react";
import { useMediaQuery } from 'react-responsive'
import { loginRequest } from "app/auth/authConfig.js";
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color: theme.palette.primary.contrastText
    },
    dialogPaper: {

        width: '400px'
    }
}));

function Login() {

    const { instance } = useMsal();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const dispatch = useDispatch();
    const dispatchMsg = useDispatch();
    const classes = useStyles();

    const [type_auth, handleTypeAuth] = useState();
    const [open, setOpen] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [email, handleEmail] = useState();
    const [logo, handleLogo] = useState([]);
    const [perfiles, handlePerfiles] = useState([]);
    const [perfil, handlePerfil] = useState();

    function message(type = "null", message = "") {
        dispatchMsg(
            Actions.showMessage({
                message: message,
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                    vertical: "top", //top bottom
                    horizontal: "center" //left center right
                },
                variant: type //success error info warning null
            })
        );
    }

    function reactStorage() {

        console.log("session: " + sessionStorage.length)

        console.log(JSON.stringify(sessionStorage))

        for (var i = 0; i < sessionStorage.length; i++) {
            var key = sessionStorage.key(i);
            try {
                var val = JSON.parse(sessionStorage.getItem(key));
                console.log(val)
            } catch {
                var val = {};
            }
            if (val.username) {
                console.log("loguearse")
                login(val.username)
            }
        }
    }

    async function login(email) {

        await axios.get(
            process.env.REACT_APP_API_HOST + "/users/profile"
            + (email ? `?email=${email}` : '')
        ).then((response) => {

            handleEmail(response.data.perfiles[0].email)

            if (response.data.perfiles.length > 1) {
                handlePerfiles(response.data.perfiles)
                setOpenP(true);
                console.log("permisos especiales");
            } else {
                console.log("iniciar sesion directamente");
                dispatch(authActions.submitEmail(email, response.data.perfiles[0].profile_slug));
            }

            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    function handleClickOpen() {
        setOpen(true);
    }

    async function handleCancelClose() {
        setOpenP(false)
        setOpen(false);
    }

    async function fetchControl() {
        let control = await ControlService.getControl();
        if (control.data.data.length > 0) {
            handleLogo(control.data.data[0].logo)
        }
    }

    async function fetchTypeAuth() {
        var result = await ControlService.getTypeAuthSelect()

        if (result.data.data.length > 0) {
            handleTypeAuth(result.data.data[0].cod_auth)

            if (result.data.data[0].cod_auth == 'WAZ') {
                reactStorage();
            }
        }

    }

    useEffect(() => {
        fetchControl()
        fetchTypeAuth()
    }, []);

    async function handleClose() {
        setOpen(false);
        if (email != "") {
            //CHAMA API PARA SALVAR
            let resultSignature = await EmployeeService.forgotPassword(email);
            console.log(resultSignature)
            if (resultSignature.status === 200) {
                if (resultSignature.data.status == 'success') {
                    message("success", resultSignature.data.message);
                } else {
                    message("error", resultSignature.data.message);
                }

            } else {
                //ERRO
                message("error", resultSignature.data.message);
            }

            handleEmail("");
        }
    }

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    function handleSubmit() {
        dispatch(authActions.submitEmail(email, perfil));
    }

    function reloadPage() {
        window.location.reload(true);
        return false;
    }

    return (
        <div className={clsx(classes.root, "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0")}>


            {isDesktopOrLaptop &&
                <div style={{ backgroundImage: 'url(assets/images/logos/talento_100.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
                    className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-12 md:items-start md:flex-shrink-0 md:flex-1 ">
                    <img className="mb-32" style={{ width: '40rem', marginLeft: "30%", marginTop: "20%" }} src={process.env.REACT_APP_API_HOST + '/' + logo} alt="logo" />
                </div>
            }

            {isTabletOrMobile &&
                <div style={{ backgroundImage: 'url(assets/images/logos/talento_100.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
                    className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-12 md:items-start md:flex-shrink-0 md:flex-1 ">
                    <img className="mb-32" style={{ width: '100%', marginLeft: "0", marginTop: "0" }} src={process.env.REACT_APP_API_HOST + '/' + logo} alt="logo" />
                </div>
            }

            <FuseAnimate animation={{ translateX: [0, '100%'] }}>

                <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>

                    <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">

                        <Typography variant="h6" className="text-center md:w-full mb-48">INGRESE A SU CUENTA</Typography>

                        {type_auth === 'WAZ' ? <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="w-full mx-auto mt-16 normal-case"
                            aria-label="LOG IN"
                            value="legacy"
                            onClick={() => handleLogin("redirect")}
                        >
                            Continuar
                        </Button> :
                            <JWTLoginTab />
                        }

                        {type_auth === 'NOR' ?
                            <span style={{ marginTop: '10px', cursor: 'pointer' }} onClick={handleClickOpen}>Olvidé mi contraseña</span>
                            :
                            ""
                        }

                        <span style={{ marginTop: '10px', cursor: 'pointer' }} onClick={reloadPage}>Refrescar Aplicación</span>

                        {<div className="flex flex-col items-center justify-center pt-32">
                            <span className="font-medium">En caso de tener problemas con el acceso a la aplicación, por favor pulse sobre "Refrescar Aplicación" para actualizar su Navegador</span>
                        </div>}

                    </CardContent>
                </Card>
            </FuseAnimate>

            <Formsy className="flex flex-col justify-center">
                <Dialog open={openP} onClose={handleClose} classes={{ paper: classes.dialogPaper }} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Seleccione el Perfil</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12} md={8}>
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                value={email}
                                label="Email"
                                fullWidth
                                disabled
                                onChange={e =>
                                    handleEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="alignRight">
                            <SelectFormsy
                                className="mb-16 fullWidthSelect"
                                name="perfil"
                                id="perfil"
                                label="Seleccione un perfil"
                                value=""
                                onChange={e => handlePerfil(e.target.value)}
                                //validations="isNotEqualToNone"
                                validationError="Por favor seleccione un perfil"
                                required
                            >

                                {perfiles.map(item =>
                                    <MenuItem key={item.profile_slug} value={item.profile_slug}>{item.profile_name}</MenuItem>
                                )}

                            </SelectFormsy>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Continuar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Formsy>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Cambio de contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Por favor, ingrese su dirección de correo para solicitar una nueva contraseña. Una contraseña temporal será enviada al correo indicado a continuación.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Dirección de correo"
                        value={email}
                        onChange={e => handleEmail(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Solicitar Cambio de Contraseña
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Login;
